<template>
  <div class="px-3">
    <div class="row mt-2">
      <div class="col-12 mt-5">
        <form class="row g-3">
          <div class="col-12">
            <center>
              <img src="@/assets/logo-ssrs.png" class="img-fluid">
              <div v-if="user">Olá {{ user.name }}!</div>
            </center>
          </div>
          <div class="col-12">
            <label for="categoria">Categoria</label>
            <select class="form-select" id="categoria" v-model="form.categoria" :disabled="button.disabled">
              <option value="listLanches" selected>Lanche</option>
              <option value="listBebidas">Bebida</option>
            </select>
          </div>
          <div class="col-12">
            <label for="senha">Senha</label>
            <input type="number" pattern="[0-9]*" inputmode="numeric" class="form-control" id="senha" placeholder="Ex.: 12345" v-model="form.senha" :disabled="button.disabled">
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled || form.senha === ''" type="button" class="btn btn-primary" @click="sendNotification()">
              <span v-if="button.loading.send" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Enviar
            </button>
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled" type="button" class="btn btn-warning" @click="repeatNotification()">
              <span v-if="button.loading.repeat" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Repetir atenção
            </button>
          </div>
          <div class="col-12 d-grid gap-2">
            <button :disabled="button.disabled" type="button" class="btn btn-secondary" @click="stopNotification()">
              <span v-if="button.loading.close" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Fechar atenção
            </button>
          </div>
          <div class="col-12 d-grid gap-2" v-if="access.admin">
            <button type="button" class="btn btn-primary" @click="pushToAdmin()">
              Ir para admin
            </button>
          </div>
          <div class="col-12 d-grid gap-2">
            <button type="button" class="btn btn-outline-dark" @click="logout()">
              Sair do sistema
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import authInfo from '../services/auth-info';
import helpersHttp from '../services/helpers-http';
export default {
  data() {
    return {
      user: authInfo(),
      access: {
        admin: false,
      },
      button: {
        disabled: false,
        loading: {
          send: false,
          repeat: false,
          close: false
        }
      },
      form: {
        categoria: 'listLanches',
        senha: ''
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.access.admin = (this.$store.state.user.permission == 'full');
    }
  },
  mounted() {
  },
  methods: {
    sendNotification() {
      this.button.disabled = true;
      this.button.loading.send = true;
      helpersHttp('new-notification', 'POST', {
        id: this.form.categoria,
        text: this.form.senha
      }).then((response) => {
        console.log(response)
        if(response.status && response.status == 'error') {
          this.accessDenied();
        }else{
          this.form.senha = '';
        }
        this.button.disabled = false;
        this.button.loading.send = false;
      }).catch((error) => {
        // console.error(error);
        this.$swal('Erro no sistema', error.message);
        this.button.disabled = false;
        this.button.loading.send = false;
      });
    },
    repeatNotification() {
      this.button.disabled = true;
      this.button.loading.repeat = true;
      helpersHttp('repeat-notification', 'POST')
      .then((response) => {
        if(response.status && response.status == 'error') {
          this.accessDenied();
        }else{
          this.form.senha = '';
        }
        this.button.disabled = false;
        this.button.loading.repeat = false;
      }).catch((error) => {
        console.error(error);
        this.$swal('Erro no sistema', error.message);
        this.button.disabled = false;
        this.button.loading.repeat = false;
      });
    },
    stopNotification() {
      this.button.disabled = true;
      this.button.loading.close = true;
      helpersHttp('stop-notification', 'POST').then((response) => {
        if(response.status && response.status == 'error') {
          this.accessDenied();
        }else{
          this.form.senha = '';
        }
        this.button.disabled = false;
        this.button.loading.close = false;
      }).catch((error) => {
        console.error(error);
        this.$swal('Erro no sistema', error.message);
        this.button.disabled = false;
        this.button.loading.close = false;
      });
    },
    pushToAdmin(){
      this.$router.push({ name: 'Admin' });
    },
    logout(){
      this.$store.dispatch('logout');
    },
    accessDenied(){
      this.$swal('Acesso negado!', 'Você não tem autorização para esta ação.', 'error');
      // localStorage.removeItem('user');
      // this.$router.push('/acesso_negado');
    }
  }
}
</script>
