import authHeader from "./auth-header";
// import router from '../router';

export default async function helpersHttp(_path, _method = 'GET', _data = {}) {
  let response = await fetch(
    process.env.API_URL + _path, {
      method: _method,
      body: JSON.stringify(_data),
      headers: authHeader(),
      cache: 'no-cache'
    }
  );

  // let _response = await response.json();
  // if(_response.status && _response.status === 'error'){
  //   localStorage.removeItem('user');
  //   router.push('/login');
  // }
  // return await _response;
  return await response.json();

}